import jQuery from 'jquery';
import * as amplitude from '@amplitude/analytics-browser';

if(typeof AMPLITUDE_KEY !== "undefined" && typeof AMPLITUDE_CURRENT_CLIENT_ID !== "undefined"){
    amplitude.init(AMPLITUDE_KEY, AMPLITUDE_CURRENT_CLIENT_ID);
}

window.jQuery = jQuery;
window.$ = jQuery;
window.amplitude = amplitude;

